export enum HIGHMARK_COMMON_ASSET_KEYS {
  HIGHMARK_PRIVACY_IMAGE = 'HIGHMARK_PRIVACY_IMAGE',
  HIGHMARK_EXIT_WARNING_IMAGE = 'HIGHMARK_EXIT_WARNING_IMAGE',
}

export enum TEST_CONFIG_FLAGS {
  forceAppWalkthrough = 'force_app_walkthrough',
  testSsoLink = 'test_sso_link',
  enableClaims = 'enable_claims',
}

export type HighmarkCommonAssets = Record<HIGHMARK_COMMON_ASSET_KEYS, unknown>;

export const throwImpersonationErrorType = {
  stack: {
    errors: [
      {
        code: 'forbidden',
      },
    ],
  },
};
