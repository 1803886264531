// We have two locations on the bottom where the (unopened) chat bubble can be located
export enum LIVE_CHAT_DROP_ZONES {
  BOTTOM_LEFT = 'bottomLeft',
  BOTTOM_RIGHT = 'bottomRight',
}

export const LiveChatDropZoneIds = {
  [LIVE_CHAT_DROP_ZONES.BOTTOM_LEFT]: 'chat-drop-left',
  [LIVE_CHAT_DROP_ZONES.BOTTOM_RIGHT]: 'chat-drop-right',
};

// Drop zone IDs are tagged to elements for drop handling
export const leftDropId = LiveChatDropZoneIds[LIVE_CHAT_DROP_ZONES.BOTTOM_LEFT];
export const rightDropId =
  LiveChatDropZoneIds[LIVE_CHAT_DROP_ZONES.BOTTOM_RIGHT];

// Drag zone IDs are tagged to elements for drag handling
export const chatDragId = 'chat-drag-container';
