import { useIntl } from '@leagueplatform/locales';
import { useHistory } from '@leagueplatform/routing';
import {
  ErrorStateProps,
  SharedErrorStateProps,
} from '@leagueplatform/web-common-components';

export enum ERROR_TYPES {
  API_ERROR = 'api_error',
  NO_CONNECTION = 'no_connection',
  NOT_ELIGIBLE = 'not_eligible',
  NOT_ELIGIBLE_MYHIGHMARK = 'not_eligible_myhighmark',
  NOT_ELIGIBLE_BENEFICITY = 'not_eligible_beneficity',
  NOT_ELIGIBLE_ALLMYHEALTH = 'not_eligible_allmyhealth',
  OVER_365_DAYS = 'over_365_days',
}

export type ErrorMessageProps = {
  errorType?: ERROR_TYPES;
  buttonText?: string;
  headingString?: string;
  bodyString?: string;
  buttonAction?: () => void;
  buttonLink?: string;
  error: SharedErrorStateProps['error'] | null;
};

type ErrorBody = {
  headingString: string;
  bodyString: string;
  buttonText?: string;
  buttonAction?: () => void;
};

export const useErrorMessage = ({
  errorType = ERROR_TYPES.API_ERROR,
  buttonText,
  headingString,
  bodyString,
  buttonAction,
  buttonLink,
  error,
}: ErrorMessageProps) => {
  const history = useHistory();
  const { formatMessage } = useIntl();

  const ErrorMessages: {
    [type in ERROR_TYPES]: ErrorBody;
  } = {
    [ERROR_TYPES.API_ERROR]: {
      headingString: 'ERROR_HEADING_GENERIC',
      bodyString: 'ERROR_CAPTION_BACKEND',
      buttonText: 'RELOAD',
      buttonAction: () => window.location.reload(),
    },
    [ERROR_TYPES.NO_CONNECTION]: {
      headingString: 'ERROR_HEADING_NO_CONNECTION',
      bodyString: 'ERROR_CAPTION_NO_CONNECTION',
    },
    [ERROR_TYPES.NOT_ELIGIBLE]: {
      headingString: 'ERROR_HEADING_NOT_ELIGIBLE',
      bodyString: 'ERROR_CAPTION_NOT_ELIGIBLE',
      buttonText: 'CLOSE',
      buttonAction: () => history.push('/sign-out'),
    },
    [ERROR_TYPES.NOT_ELIGIBLE_MYHIGHMARK]: {
      headingString: 'ERROR_HEADING_NOT_ELIGIBLE_BOXOUT',
      bodyString: 'ERROR_CAPTION_NOT_ELIGIBLE_BOXOUT_MYHIGHMARK',
      buttonText: 'GO_TO_MYHIGHMARK',
      buttonAction: () => window.location.replace(buttonLink || ''),
    },
    [ERROR_TYPES.NOT_ELIGIBLE_BENEFICITY]: {
      headingString: 'ERROR_HEADING_NOT_ELIGIBLE_BOXOUT',
      bodyString: 'ERROR_CAPTION_NOT_ELIGIBLE_BOXOUT_BENEFICITY',
      buttonText: 'GO_TO_BENEFICITY',
      buttonAction: () => window.location.replace(buttonLink || ''),
    },
    [ERROR_TYPES.NOT_ELIGIBLE_ALLMYHEALTH]: {
      headingString: 'ERROR_HEADING_NOT_ELIGIBLE_BOXOUT',
      bodyString: 'ERROR_CAPTION_NOT_ELIGIBLE_BOXOUT_ALLMYHEALTH',
      buttonText: 'GO_TO_ALLMYHEALTH',
      buttonAction: () => window.location.replace(buttonLink || ''),
    },
    [ERROR_TYPES.OVER_365_DAYS]: {
      headingString: 'ERROR_HEADING_OVER_365_DAYS',
      bodyString: 'ERROR_CAPTION_OVER_365_DAYS',
      buttonText: 'CLOSE',
      buttonAction: () => history.push('/sign-out'),
    },
  };

  let errorContentData: ErrorStateProps = {
    error,
    headingString:
      headingString ||
      formatMessage({ id: ErrorMessages[errorType].headingString }),
    bodyString:
      bodyString || formatMessage({ id: ErrorMessages[errorType].bodyString }),
  };

  if (
    ErrorMessages[errorType].buttonText &&
    ErrorMessages[errorType].buttonAction
  ) {
    errorContentData = {
      ...errorContentData,
      buttonText:
        buttonText ||
        formatMessage({ id: ErrorMessages[errorType].buttonText }),
      onButtonClick: buttonAction || ErrorMessages[errorType].buttonAction,
    };
  }

  return errorContentData;
};
