import React, { useRef } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { useGetIsImpersonator } from '@leagueplatform/auth-utils';
import { ErrorState } from 'components/error/error-state.component';
import { ERROR_TYPES } from 'hooks/use-error-message/use-error-message';
import { IframeModal } from 'components/iframe-modal/iframe-modal.component';
import { LoadingSpinner } from 'components/spinner/loading-spinner.component';
import { useAuthedWebviewModal } from 'hooks/use-authed-webview-modal/use-authed-webview-modal.hook';
import { LinkHandlerClass } from 'utils/link-handler';
import { ImpersonationErrorDisplayModalComponent } from '../impersonation-error/impersonation-error-display-modal.component';

export const AuthedWebviewModal = React.memo(
  ({ linkHandler }: { linkHandler: LinkHandlerClass }) => {
    const isImpersonation = useGetIsImpersonator();

    const iframeRef = useRef<HTMLIFrameElement>(null);
    const { closeModal, isOpen, url, error, isLoading } = useAuthedWebviewModal(
      iframeRef,
      linkHandler,
    );
    const { formatMessage } = useIntl();

    if (!isOpen) return null;

    if (isImpersonation) {
      return (
        <ImpersonationErrorDisplayModalComponent
          isOpen={isOpen}
          onClose={closeModal}
        />
      );
    }

    const modalTitle = formatMessage({ id: 'AUTHED_WEBVIEW_MODAL_TITLE' });

    return (
      <IframeModal onClose={closeModal}>
        {error ? (
          <ErrorState
            errorType={ERROR_TYPES.API_ERROR}
            hideButton
            styleProps={{ height: '100%' }}
          />
        ) : (
          <>
            {isLoading && <LoadingSpinner />}
            <iframe
              id="authedWebviewModal"
              ref={iframeRef}
              height="100%"
              width="100%"
              title={modalTitle}
              src={url}
            />
          </>
        )}
      </IframeModal>
    );
  },
);
