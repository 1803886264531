import React from 'react';
import { useDroppable } from '@dnd-kit/core';
import { Box, UtilityText } from '@leagueplatform/genesis-core';
import { css, genesisStyled } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';

export interface ChatDropProps {
  isActive: boolean;
  children?: React.ReactNode | undefined;
}

interface ChatDropZoneProps extends ChatDropProps {
  id: string;
}

const CenteredBox = genesisStyled(Box)(
  css({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }),
);

export const ChatDropContainer = ({
  id,
  isActive = false,
  children,
}: ChatDropZoneProps) => {
  const { isOver, setNodeRef } = useDroppable({ id });
  const { formatMessage } = useIntl();

  const getBorder = (): string => {
    if (!isActive) {
      return '';
    }
    return isOver
      ? '$interactiveActionHovered 3px dotted'
      : '$interactiveActionPrimary 1px dashed';
  };

  const getBackgroundColor = (): string => {
    if (!isActive) {
      return '';
    }
    return isOver
      ? '$surfaceBackgroundSecondary'
      : '$surfaceBackgroundTertiary';
  };

  return (
    <Box
      ref={setNodeRef}
      css={{
        width: '87px',
        height: '87px',
        position: 'relative',
        textAlign: 'center',
        border: getBorder(),
        borderRadius: '$extraLarge',
        backgroundColor: getBackgroundColor(),
      }}
    >
      {isActive && (
        <CenteredBox>
          <UtilityText
            css={{
              color: '$onSurfaceTextPrimary',
              width: '60px',
            }}
          >
            {formatMessage({ id: 'DROP_ZONE' })}
          </UtilityText>
        </CenteredBox>
      )}
      <CenteredBox css={{ zIndex: '$tooltip' }}>{children}</CenteredBox>
    </Box>
  );
};
