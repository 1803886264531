import { useEffect } from 'react';
import { reportAgentPermissionError } from '@leagueplatform/core';
import { ErrorState } from '../error/error-state.component';
import { throwImpersonationErrorType } from '../../types/highmark-common.types';

export const ImpersonationErrorComponent = () => {
  useEffect(() => {
    reportAgentPermissionError(true, true);
  }, []);
  return <ErrorState error={throwImpersonationErrorType} />;
};
