import React from 'react';
import { css, genesisStyled } from '@leagueplatform/genesis-commons';
import {
  ChatDropContainer,
  ChatDropProps,
} from 'components/chat-dynamic-location/drop/chat-drop-container.component';
import { Box } from '@leagueplatform/genesis-core';
import {
  leftDropId,
  rightDropId,
} from 'components/chat-dynamic-location/utils/draggable-chat-constants';

const BottomBox = genesisStyled(Box)(
  css({
    position: 'fixed',
    bottom: '0px',
    padding: 'half',
  }),
);

const BottomLeftBox = genesisStyled(BottomBox)(
  css({
    left: '0px',
  }),
);

const BottomRightBox = genesisStyled(BottomBox)(
  css({
    right: '0px',
  }),
);

// We define two drop zone locations
export const BottomLeftDropZone = ({ isActive, children }: ChatDropProps) => (
  <BottomLeftBox>
    <ChatDropContainer
      key={leftDropId}
      id={leftDropId as string}
      isActive={isActive}
    >
      {children}
    </ChatDropContainer>
  </BottomLeftBox>
);

export const BottomRightDropZone = ({ isActive, children }: ChatDropProps) => (
  <BottomRightBox>
    <ChatDropContainer
      key={rightDropId}
      id={rightDropId as string}
      isActive={isActive}
    >
      {children}
    </ChatDropContainer>
  </BottomRightBox>
);
