/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Flex, FlexProps } from '@leagueplatform/genesis-commons';
import {
  ErrorState as WebCommonErrorState,
  SharedErrorStateProps,
} from '@leagueplatform/web-common-components';
import {
  ERROR_TYPES,
  useErrorMessage,
} from 'hooks/use-error-message/use-error-message';
import { FullScreenContainer } from 'components/full-screen-container.component';

// Using explicit props instead of extending ErrorMessageProps due to IntrinsicAttributes error
export interface ErrorStateProps {
  error?: SharedErrorStateProps['error'] | null;
  isFullPage?: boolean;
  styleProps?: FlexProps;
  imageSrc?: string;
  errorType?: ERROR_TYPES;
  hideButton?: boolean;
  headingString?: string;
  bodyString?: string;
  buttonLink?: string;
  buttonAction?: () => void;
}

// error prop is required in league web to handle impersonation errors, where there is no API call pass null
export const ErrorState = ({
  styleProps,
  isFullPage = false,
  hideButton = false,
  imageSrc,
  error = null,
  ...errorData
}: ErrorStateProps) => {
  const errorContent = useErrorMessage({ ...errorData, error });

  if (isFullPage) {
    return (
      <FullScreenContainer backgroundColor="surface.background.primary">
        <Flex
          position="fixed"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          textAlign="center"
          maxWidth="350px"
          {...styleProps}
        >
          <WebCommonErrorState
            imageSrc={imageSrc}
            hideButton={hideButton}
            {...errorContent}
          />
        </Flex>
      </FullScreenContainer>
    );
  }

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      {...styleProps}
    >
      <WebCommonErrorState hideButton={hideButton} {...errorContent} />
    </Flex>
  );
};
