import React, { useEffect } from 'react';
import { useAuth } from '@leagueplatform/auth-utils';
import { LoadingSpinner } from 'components/spinner/loading-spinner.component';
import { ErrorState } from 'components/error/error-state.component';
import { ERROR_TYPES } from 'hooks/use-error-message/use-error-message';
import { captureAuthenticationError } from 'utils/sentry-helpers';

export const CSAuthSessionContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { isAuthenticated, error: authError } = useAuth({
    redirectToLogInWhenSignedOut: false,
  });

  useEffect(() => {
    if (authError) {
      captureAuthenticationError(authError);
    }
  }, [authError]);

  if (isAuthenticated === undefined) {
    return <LoadingSpinner />;
  }

  if (authError) {
    return (
      <ErrorState
        error={authError}
        errorType={ERROR_TYPES.API_ERROR}
        isFullPage
        headingString={authError.type}
        bodyString={authError.description || ''}
        hideButton
      />
    );
  }

  if (!isAuthenticated) {
    return (
      <ErrorState
        errorType={ERROR_TYPES.API_ERROR}
        headingString="Not Authenticated."
        bodyString="You are currently not logged in with an active session."
        isFullPage
        hideButton
      />
    );
  }

  return <div>{children}</div>;
};
