import React from 'react';
import { Modal } from '@leagueplatform/genesis-core';
import { ImpersonationErrorComponent } from './impersonation-error.component';

export interface ImpersonationErrorDisplayModalComponentProps {
  isOpen: boolean;
  onClose?: () => void;
}

export const ImpersonationErrorDisplayModalComponent: React.FC<
  ImpersonationErrorDisplayModalComponentProps
> = ({ isOpen, onClose }) => {
  const [modalOpen, setModalOpen] = React.useState<boolean>(isOpen);

  return (
    <Modal.Root
      open={modalOpen}
      onOpenChange={() => {
        if (onClose) return onClose();
        return setModalOpen(false);
      }}
    >
      <Modal.Trigger>
        <Modal.Content>
          <ImpersonationErrorComponent />
        </Modal.Content>
      </Modal.Trigger>
    </Modal.Root>
  );
};
